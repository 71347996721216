<template>
  <v-container>
    <v-alert text prominent type="error" icon="mdi-cloud-alert" v-if="alertError!=''">
      {{alertError}}
    </v-alert>
    <v-alert text prominent type="success" icon="mdi-cloud-alert" v-if="alertSuccess!=''">
      {{alertSuccess}}
    </v-alert>
    <template>
      <v-card class="md-auto my-5">
        <!-- <v-toolbar>
          <v-toolbar-title>School District: {{ user.schoolDistrict }} </v-toolbar-title>
          <v-toolbar-title class="mx-5">School: {{ user.school }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-title class="mx-5">Total Teacher Licences: {{ School.TeacherLicencesAssigned }}</v-toolbar-title>
          <v-toolbar-title class="mx-5">Licences Available: {{ School.AvailableTeacherLicences }}</v-toolbar-title>
          
        </v-toolbar> -->
        <v-row>
          <v-col cols="12" md="10" lg="10">
            <v-row>
              <v-col cols="6" md="3" lg="3" class="text-h6 pl-5">
                School District: {{ user.SchoolDistrict }} 
              </v-col>
              <v-col cols="6" md="3" lg="3" class="text-h6 pl-5">
                School: {{ user.School }}
              </v-col>
              <v-col cols="6" md="3" lg="3" class="text-h6 pl-5">
                Total Teacher Licences: {{ School.TeacherLicencesAssigned }}
              </v-col>
              <v-col cols="6" md="3" lg="3" class="text-h6 pl-5">
                Licences Available: {{ School.AvailableTeacherLicences }}
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="10" md="2" lg="2">
            <v-btn color="primary" dark class="mb-2" @click="dialogTeacher = true">
              New Teacher
            </v-btn>
          </v-col>
        </v-row>
      </v-card>

      <v-card class="my-5" v-if="PendingUsers.length>0">
        <v-toolbar flat color="cyan lighten-5">
          <v-toolbar-title>
            Teachers created for clever but not initialized
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <template v-for="(element, index) in PendingUsers">
          <v-chip class="ma-2" color="cyan" label text-color="white" :key="index">
            <v-icon left>
              mdi-account
            </v-icon>
            {{ element.email }}
            <v-icon right @click="DeletePreTeacher(element.email)" color="red">
              mdi-delete-empty
            </v-icon>
          </v-chip>
        </template>
      </v-card>
      <v-data-table :headers="tableHeader" :items="teachers" sort-by="calories" class="elevation-1">
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Manage School Teachers</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <p>Total Time usage: {{ TotalTimeUse}} </p>
            <v-btn color="success" class="ml-2" elevation="2" @click="ExportExcel()">Export <v-icon right dark>mdi-file-excel</v-icon></v-btn>
            <v-dialog v-model="dialogTeacher" max-width="1000px">
              <v-card>
                <v-form ref="form" v-model="valid"  @submit.prevent="save" lazy-validation>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="6">
                        <v-text-field v-model="teacher.name" :rules="nameRules" label="Name" outlined required></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="6">
                        <v-text-field v-model="teacher.lastName" :rules="nameRules" label="Last Name" outlined required></v-text-field>
                      </v-col>
                      <v-col class="d-flex" cols="12" sm="6">
                        <v-select :items="['Clever','Email and Google']" v-model="teacher.typeLogin" outlined label="Select Type Login"></v-select>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field v-model="teacher.email" :rules="emailRules" outlined label="Email"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6" v-if="teacher.typeLogin == 'Email and Google'">
                        <v-text-field v-model="teacher.password" outlined :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" :rules="[passwordRules.required, passwordRules.min]" :type="showPassword ? 'text' : 'password'" label="Password" counter @click:append="showPassword = !showPassword"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text type="submit" :disabled="dialogLoad" :loading="dialogLoad">
                    Save
                  </v-btn>
                </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-toolbar flat color="cyan lighten-4" dense>
                  <v-toolbar-title>Are you sure you want to delete?</v-toolbar-title>
                </v-toolbar>
                <v-row justify="center" class="mx-5" v-if="teacher.students>0">
                  <v-col cols="10">
                    El teacher tiene alumnos  aun asi deseas continuar?
                  </v-col>
                </v-row>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="deleteUserConfirm">SI</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <!-- <v-icon small class="mr-2" @click="editUser(item)">
            mdi-pencil
          </v-icon> -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="success" dark v-bind="attrs" v-on="on" class="mx-1" @click="editUser(item)">
                mdi-pencil
              </v-icon>
            </template>
            <span>Edit</span>
          </v-tooltip>
           <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" dark v-bind="attrs" v-on="on" class="mx-1" @click="reportUse(item)">
                mdi-chart-box
              </v-icon>
            </template>
            <span>Report</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="error" dark v-bind="attrs" v-on="on" class="mx-1" @click="deleteUser(item)">
                mdi-delete
              </v-icon>
            </template>
            <span>Delete</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="orange lighten-1" dark v-bind="attrs" v-on="on" class="mx-1" @click="reportDownloads(item)">
                mdi-file-chart<!--mdi-buffer-->
              </v-icon>
            </template>
            <span>Report Downloads</span>
          </v-tooltip>
          <!-- <v-icon small @click="deleteUser(item)" v-if="item.enabled">
            mdi-delete
          </v-icon> -->
          <!-- <v-icon small color="red accent-4" @click="sheet = !sheet" v-else>
            mdi-account-alert
          </v-icon> -->
        </template>
      </v-data-table>
    </template>
    <v-dialog v-model="dialogLoad" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Wait please
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-bottom-sheet v-model="sheet">
      <v-sheet
        class="text-center"
        height="200px"
      >
        <v-btn
          class="mt-6"
          text
          color="red"
          @click="sheet = !sheet"
        >
          close
        </v-btn>
        <div class="py-3">
          This user is disabled
        </div>
      </v-sheet>
    </v-bottom-sheet>

    <v-dialog v-model="dialogReport" width="700">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Time Usage
        </v-card-title>
        <v-card-text>
          <template v-for="(element, index) in ListMonth">
            <v-list-item :key="index">
              <v-list-item-content>
                <v-list-item-title>{{element.name}}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-list-item-action-text v-text="element.time"></v-list-item-action-text>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialogReport = false"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogLoading" hide-overlay persistent width="300">
      <v-card color="rgba(0, 143, 135, 141)" dark>
        <v-card-text>
          Wait please
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogReportDownloads" width="700">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Downloads
        </v-card-title>
        <v-card-text>          
          <template>
            <div>
              <div v-for="(item, index) in Downloads">
                <div v-if="index.slice(0,3) === 'RCS'">
                  <div class="grey lighten-5 px-1 mb-1 ">
                    <strong>Titulo:</strong> {{ item.Title }} | 
                    <strong>Num de Descargas:</strong> {{ item.Downloads }} |
                    <strong>Category:</strong> {{ item.Category }}<br>
                  </div>
                </div>                
              </div>
            </div>
          </template>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialogReportDownloads = false"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { db, functions, auth } from '../../../plugins/firebase'
import firebase from 'firebase'
export default {
  data () {
    return {
      user: {},
      tableHeader: [
        { text: 'Name', value: 'name' },
        { text: 'Last Name', value: 'lastName' },
        { text: 'Email', value: 'email' },
        { text: 'Usage time', value: 'time' },
        { text: 'Use Resources', value: 'resources' },
        { text: 'Grade', value: 'degrees' },
        //{ text: 'Classrooms', value: 'classroom' },
        { text: 'Students', value: 'students' },
        //{ text: 'Enabled', value: 'enabled' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      teachers: [],
      teacher: {
        name: '',
        lastName: '',
        email: '',
        password: '',
        typeLogin: ''
      },
      nameRules: [
        v => (v && v.length >= 2) || 'Minimum length is 2 characters',
        v => (v && v.length <= 30) || 'Name must be less than 30 characters'
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      passwordRules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 6 || 'Min 6 characters',
        emailMatch: () => (`The email and password you entered don't match`),
      },
      clearTeacher: {
        name: '',
        lastName: '',
        email: '',
        password: '',
        typeLogin: ''
      },
      editedUID: -1,
      dialogTeacher: false,
      dialogDelete: false,
      dialogLoad: false,
      valid: true,
      alertError: '',
      alertSuccess: '',
      showPassword: false,
      collection: 'SchoolTeacher',
      sheet: false,
      PendingUsers: [],
      School: {},
      TotalTimeUse: '',
      dialogReport: false,
      dialogReportDownloads: false,
      Downloads: "",
      ListMonth: [],
      dialogLoading: false,
    }
  },
  created () {
    this.SchoolAdmin();
    // db.doc('/schoolsData/RegionSchoolD/Countries/Q8MxQPZDTRqkDE4udquc/States/RAVNdnG0vulvm90d29ks/SchoolDistricts/emXtuMXQWNzeHabBptbJ/Schools/qahSVo4S3ogZstepRzNf/StudentsData/P1').get().then((res)=>{
    //   let students = res.data().Students;
    //   let ids = Object.keys(students);
    //   console.log(ids);
    // }
    // );
  },
  methods: {
    // ListStudents () {
    //   this.getStudents().then(response => {
    //     this.students = response;
    //   });
    // },
    secondsToString (seconds) {
      var hour = Math.floor(seconds / 3600);
      hour = (hour < 10)? '0' + hour : hour;
      var minute = Math.floor((seconds / 60) % 60);
      minute = (minute < 10)? '0' + minute : minute;
      var second = seconds % 60;
      second = (second < 10)? '0' + second : second;
      if (hour == '00') {
        return minute + ' m ' + second + ' s';
      }
      return hour + ' h ' + minute + ' m ' + second + ' s';
    },
    editUser (data) {
      this.editedUID = data.id;
      this.teacher = Object.assign({}, data)
      this.dialogTeacher = true
    },
    deleteUser (data) {
      this.editedUID = data.id;
      this.teacher = Object.assign({}, data)
      this.dialogDelete = true
    },
    deleteUserConfirm () {
      this.dialogLoading = true;
      // let respuesta = this.deleteData(this.editedUID);
      this.deleteData(this.editedUID).then((answer) => {
        this.dialogLoading = false;
        if (answer.result) {
          this.alertSuccess = "deleted successfully";
        } else {
          this.alertError = answer.message;
        }
        // this.SchoolAdmin();
      });
      this.closeDelete()
    },
    close () {
      this.dialogTeacher = false
      this.$refs.form.resetValidation()
      this.$nextTick(() => {
        this.teacher = Object.assign({}, this.clearTeacher)
        this.editedUID = -1
      })
    },
    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.teacher = Object.assign({}, this.clearTeacher)
        this.editedUID = -1
      })
    },
    save () {
      if (!this.$refs.form.validate()) return;
      if (this.editedUID !== -1) {
        this.dialogLoad = true;
        this.updateData(this.teacher).then(response => {
          console.log(response);
          if (response.result) {
            this.alertSuccess = "update successfully";
            let principal = this;
            setTimeout(function () { principal.alertSuccess = '' }, 3000);
          } else {
            let principal = this;
            if (Object.prototype.hasOwnProperty.call(response.message, 'code')) {
              switch (response.message.code) {
                case 'not-found':
                  this.alertError = 'not found data';
                  break;
                default:
                  this.alertError = response.message.code;
              }
            } else {
              this.alertError = response.message;
            }
            setTimeout(function () { principal.alertError = '' }, 3000);
          }
          this.dialogLoad = false
        });
      } else {
        this.dialogLoad = true;
        this.createData(this.teacher).then(response => {
          console.log(response);
          if (!response.result) {
            this.alertError = response.message;
            let principal = this;
            setTimeout(function () { principal.alertError = '' }, 3000);
          } else {
            this.alertSuccess = "se creo correctamente ";
            let principal = this;
            setTimeout(function () { principal.alertSuccess = '' }, 3000);
          }
          this.dialogLoad = false
        });
      }
      // this.SchoolAdmin();
      this.close()
      this.$refs.form.resetValidation()
    },
    validate () {
      this.$refs.form.validate()
    },
    // llamadas a firebase
    async createData (teacher) {
      let answer = { result: '', message: '' }
      try {
        if (teacher.typeLogin == 'Clever') {
          //console.log(this.user.reference.path)
          let email = this.teacher.email;
          let existe = await this.existsUser(email);
          console.log(existe);
          // return;
          if (existe) {
            answer.result = false;
            answer.message = 'this email already exists';
            return answer;
          }
            const path = this.user.Reference.path;
            let subdata = path.split("/");
            let ReferenceGrades = subdata[0] + '/' + subdata[1] + '/' + subdata[2] + '/' + subdata[3] + '/GradeData/GradeD';
            console.log('antes de batch');
            let emailSplit = email.replace(".", "_--_");
            //console.log(emailSplit)
            var batch = db.batch();
            batch.update(db.doc(this.user.Reference.path),{ [`PendingUsers.${[emailSplit]}`]: { Email:teacher.email, Name:teacher.name, LastName:teacher.lastName }, AvailableTeacherLicences: firebase.firestore.FieldValue.increment(-1)});
            batch.set(db.collection("PreUsersClever").doc(email), { Reference: db.doc(this.user.Reference.path), ListGradesReference: db.doc(ReferenceGrades), School: this.user.School, LicenseExpirationDate: this.user.PickerExpiration });
            await batch.commit();
            console.log('despues de batch');
          // await db.doc(this.user.reference.path).update({
          //   [`PendingUsers.${[emailSplit]}`]: { email:this.teacher.email, name:this.teacher.name, lastName:this.teacher.lastName }
          // });
          // await db.collection("PreUsersClever").doc(email).set({
          //   reference: db.doc(this.user.reference.path)
          // })
          this.GetPendingUsers();
          answer.result = true;
          return answer;
        }
        teacher.collection = this.collection;
        teacher.school = this.user.School;
        teacher.reference = this.user.Reference.path;
        teacher.dateExpiration = this.user.PickerExpiration.toDate().getTime();
        const path = this.user.Reference.path;
        let subdata = path.split("/");
        let ReferenceGrades = subdata[0] + '/' + subdata[1] + '/' + subdata[2] + '/' + subdata[3] + '/GradeData/GradeD';
        teacher.ListGradesReference = ReferenceGrades;
        teacher.Multiplayer = false;
        if (this.School.Multiplayer != undefined){
          if (this.School.Multiplayer>0){
            teacher.Multiplayer = false;
          }
        }
        console.log(teacher)
        const setAdmin = functions.httpsCallable('createTeacher')
        await setAdmin(teacher);
        answer.result = true;
      } catch (error) {
        answer.result = false;
        answer.message = error;
        // console.log(error);
      }
      return answer;
    },
    async existsUser (email) {
      let signInMethods = await auth().fetchSignInMethodsForEmail(email);
      if (signInMethods.length == 0) {
        let docVerify = await db.collection("PreUsersClever").doc(email).get();
        if (!docVerify.exists) {
          console.log('No existe!');
          return false;
        } else {
          console.log('si existe');
          return true
        }
              
      } else {
        console.log(signInMethods)
        console.log('si existe')
        return true;
      }
    },
    async updateData (teacher) {
      let answer = { result: '', message: '' }
      // console.log(teacher);
      // return;
      try {
        if (teacher.typeLogin == 'Clever') {
          throw new Error('no puede editar con usuarios clever')
        }
        teacher.collection = this.collection;
        teacher.school = this.user.School;
        teacher.reference = this.user.Reference.path;
        
        const updatetAdmin = functions.httpsCallable('updateTeacher')
        await updatetAdmin(teacher)
        answer.result = true;
        // await db.collection("SchoolAdmin").doc(user.id).update(user)
        //   .then(() => {
        //     answer.resultado = true;
        //   })
        //   .catch((error) => {
        //     answer.resultado = false;
        //     answer.mensaje = error;
        //   });
      } catch (error) {
        answer.result = false;
        answer.message = error;
        console.log('entro al log general');
      }
      return answer;
    },
    async deleteData (id) {
      // let answer = { resultado: '', mensaje: '' }
      // db.collection(this.table).doc(id).delete().then(() => {
      //   answer.resultado = true;
      // }).catch((error) => {
      //   answer.mensaje = error;
      //   answer.resultado = false;
      // })
      // return answer;
      let answer = { resutl: '', message: '' }
      let reference = this.user.Reference.path;
      let data = {
        id,
        reference
      }
      try {
        console.log(this.teacher);
      let teacherData = await db.collection("SchoolTeacher").doc(this.teacher.id).get();
      let classTeacher= teacherData.data();
      console.log(classTeacher);
      let idsClassrooms = Object.keys(classTeacher.Classrooms);
      let students = [];

      for (const clase of idsClassrooms) {
        console.log(clase);
        let temporalClass = await db.collection("SchoolTeacher").doc(classTeacher.UserUID).collection("Classrooms").doc(clase).get();
        let dataTemporal = temporalClass.data();

        let temporalStudents = Object.keys(dataTemporal.MainGroup);
        students.push(...temporalStudents)
        await db.collection("SchoolTeacher").doc(clase).delete();
      }
      console.log(students);
      for (let index = 0; index < students.length; index++) {
        await db.doc(classTeacher.Reference.path + '/StudentsData/P1').update( { [`Students.${students[index]}.UserUIDSTeacher`]: '' });
        
      }

        const deleteTeacher = functions.httpsCallable('deleteTeacher')
        await deleteTeacher(data)
        answer.result = true;
      } catch (error) {
        answer.message = error;
        answer.resutl = false;
      }
      return answer;
    },

    async Users () {
      await db.collection(this.table).onSnapshot((snapshotChange) => {
        this.users = [];
        snapshotChange.forEach((doc) => {
          let oneStudent = doc.data();
          oneStudent.id = doc.id;
          this.users.push(oneStudent);
        });
      }, (error) => {
        console.log(error);
      })
    },
    GetPendingUsers (){
      this.PendingUsers = [];
      db.doc(this.user.Reference.path).get().then((doc) => {
        //console.log(doc.data())
        let teachersPending = doc.data().PendingUsers;
        let ids = Object.keys(teachersPending);
        ids.forEach(element => {
          this.PendingUsers.push({email:teachersPending[element].Email, name:teachersPending[element].Name })
        });
        this.School = { AvailableTeacherLicences: doc.data().AvailableTeacherLicences , TeacherLicencesAssigned: doc.data().TeacherLicencesAssigned, Multiplayer: doc.data().MultiplayerLicenses }
      })
    },
    SchoolAdmin(){
      let uidLogin = this.$store.state.user.uid;
      db.collection('SchoolAdmin').doc(uidLogin).get().then((doc)=>{
        this.user = doc.data();
        console.log(doc.data());
        this.user.Reference.onSnapshot((ref) => {
              console.log("Current data: ", ref.data());
              this.ListData(ref.data().SchoolTeachers)
          });
      })
      
    },
    async ListData (ListTeachers) {      
      // aca deberiamos verificar si el usuario expiro
      // listamos
      // let users = await this.user.Reference.get();
      // let ListTeachers = users.data().SchoolTeachers;
      let ids = Object.keys(ListTeachers);
      let newTeachers = [];
      let TotalTime= 0;
       ids.forEach(async element => {
        let userTime = await db.collection('TimeUse').doc(element).get();
        let TimeUse = 0;
        if (userTime.exists) {
          let idsTime = Object.keys(userTime.data());
          let partialTime = 0;
          
          idsTime.forEach(element => {
            partialTime+= userTime.data()[element];
          });
          TimeUse = this.secondsToString(Math.round( partialTime ));
          TotalTime +=partialTime;
          this.TotalTimeUse = this.secondsToString(Math.round( TotalTime ));
          //console.log(TotalTime)
        }
        let AllData = await db.collection('SchoolTeacher').doc(element).get();
        let teacherData =AllData.data();
        let UseResources = 'no';
        //let ClassroomsCreated = 0;
        if (Object.prototype.hasOwnProperty.call(teacherData, 'Downloads')) {
          UseResources = 'yes';
        }
        let ClassroomsCreated = Object.keys(teacherData.Classrooms).length;
        let students = 0;
        let namesClassrooms = '';
        if (ClassroomsCreated>0) {
          let clases = Object.keys(teacherData.Classrooms);
          //console.log(clases);
          for (const ClassroomSelected of clases) {
            let TempClassData = await db.collection('SchoolTeacher').doc(element).collection('Classrooms').doc(ClassroomSelected).get();
            namesClassrooms+= TempClassData.data().Grade+',';
            students += teacherData.Classrooms[ClassroomSelected].NumberStudents;
          }
          // for (let index = 0; index < clases.length; index++) {
          //   let TempClassData = await db.collection('SchoolTeacher').doc(element).collection('Classrooms').doc(clases[index]).get();
          //   namesClassrooms += TempClassData.data().grade+','
          //   students += teacherData.Classrooms[clases[index]].numberStudents;
          //   console.log(namesClassrooms);
          // }
          // clases.forEach(async ClassroomSelected => {
          //   let TempClassData = await db.collection('SchoolTeacher').doc(element).collection('Classrooms').doc(ClassroomSelected).get();
          //   namesClassrooms+= TempClassData.data().grade+','
          //   students += teacherData.Classrooms[ClassroomSelected].numberStudents;
          //   console.log(namesClassrooms);

          // });
        }
        let login = "";
        if (Object.prototype.hasOwnProperty.call(teacherData, 'TypeLogin')) {
          if (teacherData.TypeLogin == 'email'){
            login = 'email';
          }
        }
        console.log(namesClassrooms)
        newTeachers.push({ id: element, name: ListTeachers[element].Name, lastName: ListTeachers[element].LastName, email: ListTeachers[element].Email, enabled: ListTeachers[element].Enabled, time: TimeUse, resources:UseResources, classroom : ClassroomsCreated, students: students, degrees:namesClassrooms, login:login, Downloads: teacherData.Downloads })
      });
      this.teachers = newTeachers;
      this.GetPendingUsers();
    },
    async DeletePreTeacher(email) {
      this.dialogLoad = true;
      const deletePreTeacher = functions.httpsCallable('deletePreTeacher')
      await deletePreTeacher({email:email, reference: this.user.Reference.path})
        .then((res) => {
          this.dialogLoad = false;
          this.GetPendingUsers();
          //console.log(res)
        })
    },
    async reportUse (item){
      if (item.time == 0) {
        this.alertError = 'This user has not registered time';
        let principal = this;
        setTimeout(function () { principal.alertError = '' }, 3000);
        return
      }
      this.dialogReport = true
      const mesActual = new Date().getMonth();
      // const monthNames = ["January", "February", "March", "April", "May", "June",
      //   "July", "August", "September", "October", "November", "December"
      // ];
      this.ListMonth = [];
      const monthNames = [
        { name: 'January', time :0},{ name: 'February', time :0},{ name: 'March', time :0},
        { name: 'April', time :0},{ name: 'May', time :0},{ name: 'June', time :0},
        { name: 'July', time :0},{ name: 'August', time :0},{ name: 'September', time :0},
        { name: 'October', time :0},{ name: 'November', time :0},{ name: 'December', time :0}
      ];
      
      //console.log(mesActual)   
      let userTime = await db.collection('TimeUse').doc(item.id).get();
      let idsTime = Object.keys(userTime.data());
      idsTime.forEach(element => {
        let month = new Date(+element).getMonth(); 
        monthNames[month].time += userTime.data()[element];
      });
      //aqui convertimos y reordenamos
      monthNames.forEach(element => {
        if (element.time!=0) {
          element.time = this.secondsToString(Math.round( element.time ));
          this.ListMonth.push(element)
        }
      });
      //console.log(new Date(+1635742452606).toLocaleString())
    },
    async reportDownloads(item){console.log(item);//this.teachers
      if (typeof(item.Downloads)==='undefined'){
        this.alertError = 'This user has no downloads.';
        let principal = this;
        setTimeout(function () { principal.alertError = '' }, 3000);
        return
      }
      
      this.dialogReportDownloads = true;
      this.Downloads=item.Downloads;
    },
    ExportExcel(){
      let dia = new Date();
      let table2excel = new Table2Excel();
      table2excel.export(document.getElementsByTagName("table"),"Teachers "+dia.toLocaleDateString());
    }
  },
  computed: {
    formTitle () {
      return this.editedUID === -1 ? 'New Teacher' : 'Edit Teacher'
    },
  },
  watch: {
    dialogTeacher (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
    dialogLoad (val) {
      // if (!val) return
      // console.log(this.$nuxt.isOffline);
      // setTimeout(() => (this.dialogLoad = false), 4000)
    },
  }
}
</script>
